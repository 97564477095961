/* eslint-disable no-console */
import { safeStringify } from './random';
import { captureException, captureMessage, withScope } from '@sentry/nextjs';
import { type Primitive, type SeverityLevel } from '@sentry/types';
import { getEnvironmentVariable } from './envVars';

interface PitchGhostLog {
    /**
     * A plain text message to log.
     * Will be used as the error if no error is provided
     */
    message: string;

    /**
     * An error to log.
     */
    error?: Error | unknown;

    /**
     * The log level to use.
     * @default error
     */
    level?: SeverityLevel;

    /**
     * The user id involved in the log
     */
    uid?: string;

    /**
     * The company id involved in the log
     */
    company_id?: string;

    /**
     * Any extra data to log
     */
    extra?: Record<string, unknown>;

    /**
     * Any tags to log
     */
    tags?: {
        [key: string]: Primitive;
    };
}

/**
 * This is a PitchGhost specific form for logging. Using this function will capture the best data for study
 * and will route the the logs to the correct place.
 * @param data The data to log
 * @returns Nothing
 */
export const log = (data: PitchGhostLog) => {
    const logLevel = data.level ?? 'error';

    if (getEnvironmentVariable(process.env.NODE_ENV) !== 'production') {
        switch (logLevel) {
            case 'debug': {
                if (data.error) {
                    console.debug(
                        `[${logLevel.toUpperCase()}]`,
                        data.message,
                        data.error,
                        safeStringify(data.extra)
                    );
                } else {
                    console.debug(
                        `[${logLevel.toUpperCase()}]`,
                        data.message,
                        safeStringify(data.extra)
                    );
                }
                break;
            }
            case 'info': {
                if (data.error) {
                    console.info(
                        `[${logLevel.toUpperCase()}]`,
                        data.message,
                        data.error,
                        safeStringify(data.extra)
                    );
                } else {
                    console.info(
                        `[${logLevel.toUpperCase()}]`,
                        data.message,
                        safeStringify(data.extra)
                    );
                }
                break;
            }
            case 'warning': {
                if (data.error) {
                    console.warn(
                        `[${logLevel.toUpperCase()}]`,
                        data.message,
                        data.error,
                        safeStringify(data.extra)
                    );
                } else {
                    console.warn(
                        `[${logLevel.toUpperCase()}]`,
                        data.message,
                        safeStringify(data.extra)
                    );
                }
                break;
            }
            case 'error': {
                if (data.error) {
                    console.error(
                        `[${logLevel.toUpperCase()}]`,
                        data.message,
                        data.error,
                        safeStringify(data.extra)
                    );
                } else {
                    console.error(
                        `[${logLevel.toUpperCase()}]`,
                        data.message,
                        safeStringify(data.extra)
                    );
                }
                break;
            }
        }

        return;
    }

    switch (logLevel) {
        case 'debug':
        case 'log':
        case 'info': {
            if (data.error) {
                console.info(
                    `[${logLevel.toUpperCase()}]`,
                    data.message,
                    data.error,
                    safeStringify(data.extra)
                );
            } else {
                console.info(
                    `[${logLevel.toUpperCase()}]`,
                    data.message,
                    safeStringify(data.extra)
                );
            }
            break;
        }
        case 'error':
        case 'fatal':
        case 'warning': {
            if (data.error) {
                console.error(
                    `[${logLevel.toUpperCase()}]`,
                    data.message,
                    data.error,
                    safeStringify(data.extra)
                );
            } else {
                console.error(
                    `[${logLevel.toUpperCase()}]`,
                    data.message,
                    safeStringify(data.extra)
                );
            }

            withScope((scope) => {
                scope.setLevel(logLevel);
                scope.setUser({ id: data.uid, company_id: data.company_id });
                scope.setContext('Company', { id: data.company_id });
                if (data.tags) {
                    scope.setTags(data.tags);
                }

                const extraData = data.extra ?? {};
                if (data.extra) {
                    Object.keys(data.extra).forEach((key) => {
                        if (typeof data.extra?.[key] === 'object') {
                            extraData[key] = safeStringify(data.extra[key]);
                        } else if (typeof data.extra?.[key] !== 'undefined') {
                            extraData[key] = data.extra?.[key];
                        }
                    });
                }

                for (const [key, value] of Object.entries(extraData)) {
                    scope.setExtra(key, value);
                }

                const errorMessage = data.error ? safeStringify(data.error) : undefined;
                if (errorMessage) {
                    scope.addBreadcrumb({
                        message: data.message,
                        data: {
                            error: errorMessage,
                        },
                        level: logLevel,
                    });
                }

                if (data.error && data.error instanceof Error) {
                    return captureException(data.error);
                }

                return captureMessage(data.message, logLevel);
            });
            break;
        }
    }
};
