import { clsx, type ClassValue } from 'clsx';
import { Children, isValidElement } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * Merge all class names together and return a single string
 * @param inputs Later class names will override earlier ones
 * @returns The merged class names
 */
export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function getValidChildren(children: React.ReactNode) {
    return Children.toArray(children).filter((child) =>
        isValidElement(child),
    ) as React.ReactElement[];
}
