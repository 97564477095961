import type { Database, TablesInsert } from './supabase';
import type { GenericGhostDetails } from '@/utils/ghostDetailsSchema';
import type { NotificationsSchema } from '@/app/org/[company_id]/ghosts/[ghost_id]/notifications/notificationFormSchema';
import { log } from '@/utils/logger';
import { z } from 'zod';
import { toast, type ExternalToast } from 'sonner';
import { getEnvironmentVariable } from '@/utils/envVars';

export enum ScanStatus {
    SCANNING = 'SCANNING',
    EMBEDDING = 'EMBEDDING',
    FINISHING = 'FINISHING',
    DONE = 'DONE',
    ERROR = 'ERROR',
}

export type Error = {
    error: string;
};

export type Profile = Database['public']['Tables']['profiles']['Row'];

export type Company = Database['public']['Tables']['companies']['Row'];

export type CompanyMemberRelationship =
    Database['public']['Tables']['company_member_relationships']['Row'];

export type Ghost = Omit<
    Database['public']['Tables']['ghosts']['Row'],
    'frequency' | 'notifications'
> & {
    frequency: GenericGhostDetails['frequency'];
    notifications: NotificationsSchema | null;
};

export enum RunStatus {
    running = 'running',
    succeeded = 'succeeded',
    failed = 'failed',
    skipped = 'skipped',
    offline = 'offline',
    queued = 'queued',
}

// This is a hack to make sure to have a static check on the run status
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ensureRunStatusCompatibility: {
    [K in RunStatus]: Database['public']['Enums']['run_status'];
} = {
    running: 'running',
    succeeded: 'succeeded',
    failed: 'failed',
    skipped: 'skipped',
    offline: 'offline',
    queued: 'queued',
};

export type Run = Omit<Database['public']['Tables']['runs']['Row'], 'status'> & {
    status: RunStatus;
};

export type RunWithFindCount = Run & {
    finds?: [
        {
            count: number;
        },
    ];
};

export type Subscription = Database['public']['Tables']['subscriptions']['Row'];

export enum UserRoles {
    member = 'member',
    admin = 'admin',
    owner = 'owner',
}

export type Invite = Database['public']['Tables']['invites']['Row'];

// This is a hack to make sure to have a static check on the user roles
// type coming from Supabase
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const staticUserRolesCheck: { [key in Database['public']['Enums']['role']]: key } = {
    member: 'member',
    admin: 'admin',
    owner: 'owner',
};

export function isUserRole(value: unknown): value is UserRoles {
    return Object.values(UserRoles).includes(value as UserRoles);
}

// Change this to change the order on the page
export const IntegrationServices = [
    'slack',
    'twitter',
    'linkedin',
    'reddit',
    'facebook',
    'zapier',
    'hubspot',
    'discord',
] as const;

export type IntegrationService = (typeof IntegrationServices)[number];

// Must be a value from IntegrationService
export type ExtensionService = Exclude<
    IntegrationService,
    Exclude<IntegrationService, 'linkedin' | 'twitter'>
>;

export type ExtensionAccountRecord = Database['public']['Tables']['integration_extension']['Row'];

export const isIntegrationService = (value: unknown): value is IntegrationService => {
    return IntegrationServices.includes(value as IntegrationService);
};

export type Integration = Database['public']['Tables']['integrations']['Row'];

export type SlackIntegrationDetails = Database['public']['Tables']['integration_slack']['Row'];
export type ClientSafeSlackIntegrationDetails = Omit<
    SlackIntegrationDetails,
    'access_token' | 'oauth_response'
>;
export type TwitterIntegrationDetails = Database['public']['Tables']['integration_twitter']['Row'];

type SuccessfulActionResponse<T = undefined> = T extends undefined
    ? { success: true }
    : { success: true; data: Exclude<T, undefined> };

type FailedActionResponse = {
    success: false;
    error?: string;
};

export type ActionResponse<T = undefined> =
    | SuccessfulActionResponse<T>
    | FailedActionResponse
    | undefined;

export const ActionResponseSchema = z.union([
    z.object({
        success: z.literal(true),
        data: z.unknown(),
    }),
    z.object({
        success: z.literal(false),
        error: z.string().optional(),
    }),
]);

export type WebEmbeddingResult =
    Database['public']['Functions']['match_web_documents']['Returns'][0];

export type Contact = Database['public']['Tables']['contacts']['Row'];
export type ExternalContactRecord = Database['public']['Tables']['external_contacts']['Row'];
export type ExternalContactData = {
    type: Database['public']['Tables']['external_contacts']['Row']['type'];
    value: Database['public']['Tables']['external_contacts']['Row']['value'];
    url: Database['public']['Tables']['external_contacts']['Row']['url'];
};

export type Find = Database['public']['Tables']['finds']['Row'];

export type FindWithContact = Find & {
    contacts: Contact | null;
};

export type FindAction = Database['public']['Tables']['find_actions']['Row'];

/**
 * Type guard to make sure the find result is valid
 */
export function isFindResult(value: unknown): value is Find['result'] {
    return ['none', 'failed', 'goal'].includes(value as string);
}

export interface DataTableUrlControls {
    pageSize?: string;
    page?: string;
    sortDirection?: 'asc' | 'desc';
    sortKey?: string;
    hiddenKeys?: string;
    search?: string;
}

export interface ParsedTableDataControls {
    pageSize: number;
    page: number;
    sortDirection: boolean;
    sortKey: string;
    hiddenKeys: string[];
    search?: string;
}

export type WithCount<T> = {
    count: number;
    data: T;
};

export type PlanType = Database['public']['Enums']['subscription_plan'];

export const orgMemberSchema = z.object({
    uid: z.string(),
    full_name: z.string(),
    profile_picture: z.string(),
    email: z.string(),
    role: z.nativeEnum(UserRoles).optional(),
});

export type OrgMember = z.infer<typeof orgMemberSchema>;

export type RateLimitedFindAction = Database['public']['Tables']['retries']['Row'];

export type PlatformAlert = Database['public']['Tables']['platform_alerts']['Row'];

/**
 * Constructs a new type by selecting a subset of properties from an existing type.
 * @template T - The type to select properties from.
 * @template K - The keys of the properties to select.
 */
export type Subset<T, K extends keyof T> = {
    [P in K]: T[P];
};

export type ElementType<T> = T extends (infer E)[] ? E : never;

/**
 * https://nextjs.org/docs/app/api-reference/file-conventions/route-segment-config#dynamic
 * @default 'auto'
 */
export type NextDynamicOption = 'auto' | 'force-dynamic' | 'error' | 'force-static';

/**
 * https://nextjs.org/docs/app/api-reference/file-conventions/route-segment-config#dynamicparams
 * @default true
 */
export type NextDynamicParamsOption = boolean;

/**
 * https://nextjs.org/docs/app/api-reference/file-conventions/route-segment-config#revalidate
 * @default false
 */
export type NextRevalidateOption = false | 0 | number | 'force-cache';

/**
 * https://nextjs.org/docs/app/api-reference/file-conventions/route-segment-config#fetchcache
 * @default 'auto'
 */
export type NextFetchCacheOption =
    | 'auto'
    | 'default-cache'
    | 'only-cache'
    | 'force-cache'
    | 'force-no-store'
    | 'default-no-store'
    | 'only-no-store';

/**
 * https://nextjs.org/docs/app/api-reference/file-conventions/route-segment-config#runtime
 * @default 'nodejs'
 */
export type NextRuntimeOption = 'nodejs' | 'edge';

/**
 * https://nextjs.org/docs/app/api-reference/file-conventions/route-segment-config#preferredregion
 * @default 'auto'
 */
export type NextPreferredRegionOption =
    | 'auto'
    | 'global'
    | 'home'
    | 'cdg1'
    | 'arn1'
    | 'dub1'
    | 'lhr1'
    | 'iad1'
    | 'sfo1'
    | 'pdx1'
    | 'cle1'
    | 'gru1'
    | 'hkg1'
    | 'hnd1'
    | 'icn1'
    | 'kix1'
    | 'sin1'
    | 'bom1'
    | 'syd1'
    | 'fra1'
    | 'cpt1';

/**
 * https://nextjs.org/docs/app/api-reference/file-conventions/route-segment-config#maxduration
 * @default 'auto'
 */
export type NextMaxDurationOption = number;

/**
 * This function will check what environment the code is running in.
 * @returns The environment the code is running in. Either 'node', 'browser', or 'edge'
 * or false if it can't be detected.
 */
export function detectEnvironment() {
    try {
        // Check for Browser environment
        if (typeof window !== 'undefined') {
            return 'browser';
        }

        // Check for Node.js environment
        // eslint-disable-next-line @pitchghost/ensure-safe-env-vars
        if (getEnvironmentVariable(process.env.NEXT_RUNTIME) === 'nodejs') {
            return 'node';
        }

        // Default to Vercel's Edge runtime
        return 'edge';
    } catch (error) {
        log({
            message: 'Error detecting environment',
            level: 'error',
            error,
        });
        return false;
    }
}

/**
 * This function will pop an error toast with the given message
 * or a default message if none is provided.
 * It will also make sure that only one toast is shown for each error message.
 * @param message The message to show in the toast
 */
export const toastError = (message?: string, settings?: ExternalToast) => {
    const environment = detectEnvironment();

    if (environment === false || environment === 'node' || environment === 'edge') {
        log({
            message: 'wrong environment for toast',
            level: 'warning',
            extra: {
                environment,
                message,
            },
        });
        return;
    }

    const text = message || 'An error occurred. Please try again later.';
    const encoder = new TextEncoder();
    const data = encoder.encode(text);
    crypto.subtle
        .digest(
            {
                name: 'SHA-1',
            },
            data
        )
        .then((hash) => {
            const hashArray = Array.from(new Uint8Array(hash));
            const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
            // eslint-disable-next-line no-restricted-syntax -- This is the one spot it's okay
            toast.error(text, {
                id: hashHex,
                ...settings,
            });
        })
        .catch((error) => {
            log({
                message: 'Error generating toast error hash',
                level: 'error',
                error,
            });
        });
};

export const statsTimeValues = [
    '1 Week',
    '2 Weeks',
    '1 Month',
    '3 Months',
    '6 Months',
    '1 Year',
    'All time',
] as const;

export const statsTimeSchema = z.enum(statsTimeValues);

export type StatsTimeValue = z.infer<typeof statsTimeSchema>;

export const convertStatsTimeValueToDays = (timeValue: z.infer<typeof statsTimeSchema>) => {
    switch (timeValue) {
        case '1 Week':
            return 7;
        case '2 Weeks':
            return 14;
        case '1 Month':
            return 30;
        case '3 Months':
            return 90;
        case '6 Months':
            return 180;
        case '1 Year':
            return 365;
        case 'All time':
            return 100_000;
    }
};

/**
 * This function takes in a string or undefined and returns a valid time frame.
 * If the passed in value wasn't a timeFrame it will return the default value
 * of 1 Month.
 * @param timeFrame The time frame to validate
 * @returns The validated time frame or the default value
 */
export const validateTimeFrame = (timeFrame?: string): StatsTimeValue => {
    let final: StatsTimeValue | undefined;
    if (timeFrame) {
        const parsed = statsTimeSchema.safeParse(timeFrame);
        if (!parsed.success) {
            toastError('Invalid time frame');
        } else {
            final = parsed.data;
        }
    }

    return final ?? '1 Month';
};

export interface CountByDay {
    day: string;
    count: number;
}

export type FindActionUpsert = TablesInsert<'find_actions'>;

/**
 * Function to check balanced quotes and parentheses
 * @param query The search query to validate
 * @returns Whether the string has balanced quotes and parentheses
 */
export const checkQuoteAndParenthesesBalance = (query: string): boolean => {
    const stack = [];
    let inQuote = false;

    for (let i = 0; i < query.length; i++) {
        const char = query[i];
        if (char === '"' && !inQuote) {
            inQuote = true;
        } else if (char === '"' && inQuote) {
            inQuote = false;
        } else if (char === '(' && !inQuote) {
            stack.push('(');
        } else if (char === ')' && !inQuote) {
            if (stack.pop() !== '(') {
                return false;
            }
        }
    }

    return stack.length === 0 && !inQuote;
};

/**
 * Splits an array into chunks of the specified size.
 * @param array The array to split.
 * @param chunkSize The size of each chunk.
 * @returns An array of chunks.
 */
export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
    const chunks: T[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
}

/**
 * This simple typescript checker function will throw an error if it is passed a value.
 * Use this function in a switch statement to ensure that all cases are handled.
 * @param x The switched value that should have been handled in all cases
 */
export function assertNever(x: never): never {
    throw new Error('Unexpected object: ' + x);
}

export const redditUserAccountInfoDbInfoSchema = z.object({
    id: z.string(),
    username: z.string(),
    profile_img: z.string(),
});

export type RedditUserAccountInfoDbInfo = z.infer<typeof redditUserAccountInfoDbInfoSchema>;

export type RedditIntegration = Database['public']['Tables']['integration_reddit']['Row'] & {
    account_info: RedditUserAccountInfoDbInfo;
};

export type ClientSafeRedditIntegration = {
    account_info: RedditUserAccountInfoDbInfo;
    id: string;
    permission: Database['public']['Tables']['integration_reddit']['Row']['permission'];
    linked_by: {
        uid: string;
        full_name: string;
        profile_picture: string;
    };
    status: Database['public']['Tables']['integration_reddit']['Row']['status'];
    company_id: string;
};

export const clientSafeRedditIntegrationSchemaPARTIAL = z.object({
    account_info: redditUserAccountInfoDbInfoSchema,
    id: z.string(),
});
