import type { ReadonlyURLSearchParams } from 'next/navigation';
import type { Cookie } from './extension/clientExtensionHelpers';

/**
 * Tests if a given string is a valid domain address using the given regex
 * @param domain The string to test.
 * @returns True if the string is a valid domain address, false otherwise.
 */
export function isValidDomain(domain: string): boolean {
    return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(domain);
}

/**
 * Waits for a given number of milliseconds.
 * @param ms - The number of milliseconds to wait.
 * @returns - A promise that resolves after the given number of milliseconds.
 */
export async function delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * A function that takes in a full name and returns the first
 * letter of the first name and the last name, if possible.
 */
export function getInitials(fullName: string | null): string {
    if (!fullName) {
        return '';
    }
    const names = fullName.split(' ');
    let output = '';
    if (names[0]) {
        output = names[0].charAt(0);
    }
    if (names.length > 1 && names[1]) {
        output += names[1].charAt(0);
    }

    return output;
}

/**
 * Takes in a string and returns the same string with the first letter
 * capitalized.
 * @param word - The string to capitalize.
 * @returns - The capitalized string.
 */
export function uppercaseFirstLetter(word: string | null | undefined): string {
    if (!word || word.length === 0) {
        return '';
    } else if (word.length === 1) {
        return word.toUpperCase();
    }

    return word.charAt(0).toUpperCase() + word.slice(1);
}

/**
 * Returns an editable copy of the given URLSearchParams object.
 * @param searchParams The next/navigation URLSearchParams object.
 * @returns URLSearchParams object.
 */
export const getCurrentSearchParams = (searchParams: ReadonlyURLSearchParams) => {
    return new URLSearchParams(Array.from(searchParams.entries()));
};

/**
 * This function will sanitize a string to remove any emoji characters.
 * @param input The string to sanitize.
 * @returns The sanitized string.
 */
export function sanitizeEmoji(input: string | null): string | null {
    if (!input) {
        return null;
    }
    return input.replace(/(\s?[\p{Emoji_Presentation}\p{Extended_Pictographic}]\s?)/gu, ' ').trim();
}

/**
 * This function will filter an array asynchronously.
 * @param arr The array to filter.
 * @param predicate The predicate to filter the array with.
 * @returns A promise that resolves to the filtered array.
 */
export async function asyncFilter<T>(
    arr: T[],
    predicate: (item: T) => Promise<boolean>
): Promise<T[]> {
    const results = await Promise.all(arr.map(predicate));
    return arr.filter((_v, index) => results[index]);
}

/**
 * This function will map an array asynchronously.
 * @param array The array to map.
 * @param callback The callback to map the array with.
 * @returns The mapped array.
 */
export async function asyncMap<T, U>(
    array: T[],
    callback: (item: T, index: number, array: T[]) => Promise<U>
): Promise<U[]> {
    return Promise.all(array.map(callback));
}

export function useManualSuspense(): [() => void, () => void] {
    let resolver: () => void = () => {};
    const promise: Promise<void> = new Promise<void>((resolve) => {
        resolver = resolve;
    });

    const triggerSuspense = () => {
        throw promise;
    };

    const resolveSuspense = () => {
        resolver();
    };

    return [triggerSuspense, resolveSuspense];
}

/**
 * This will attempt to convert any object or value to a string.
 * F
 * @param obj The object to stringify.
 * @returns A string representation of the object. If the object cannot be stringified, an error message will be returned.
 */
export function safeStringify(obj: unknown): string {
    try {
        return JSON.stringify(obj, null, 2);
    } catch (error) {
        if (error instanceof Error) {
            return `Error in serialization: ${error.message}`;
        }
        return 'Unknown error in serialization';
    }
}

/**
 * This function will efficiently split an array into two arrays based on a predicate.
 * @param array The array to split.
 * @param predicate The predicate to split the array with.
 * @returns An array containing two arrays. The first array contains all elements that passed the predicate, the second array contains all elements that failed the predicate.
 */
export function splitArray<T>(array: T[], predicate: (elem: T) => boolean): [T[], T[]] {
    return array.reduce<[T[], T[]]>(
        ([pass, fail], elem): [T[], T[]] => {
            if (predicate(elem)) {
                pass.push(elem);
            } else {
                fail.push(elem);
            }
            return [pass, fail];
        },
        [[], []]
    );
}

/**
 * This function will attempt to split an array asynchronously based on a predicate.
 * @param array The array to split.
 * @param predicate The predicate to split the array with.
 * @returns The split arrays
 */
export async function asyncSplitArray<T>(
    array: T[],
    predicate: (elem: T) => Promise<boolean>
): Promise<[T[], T[]]> {
    return array.reduce(
        async (acc, elem) => {
            const [pass, fail] = await acc;
            if (await predicate(elem)) {
                pass.push(elem);
            } else {
                fail.push(elem);
            }
            return [pass, fail];
        },
        Promise.resolve<[T[], T[]]>([[], []])
    );
}

/**
 * This function will attempt to clean the output of the AI.
 * The AI will sometimes output a string with it's internal formatting
 * of JSON instead of just the raw string which can be converted to JSON.
 * the telltale sign of this is the string starting with ```json\n.
 * It may also end with ```. If this is the case, we will attempt to
 * remove the formatting and return the raw string.
 * @param input The AI output to clean.
 * @returns The cleaned AI output.
 */
export const extraCleanAIOutput = (input: string): string => {
    if (input.startsWith('```json\n')) {
        input = input.replace('```json\n', '');
    }
    if (input.endsWith('```')) {
        input = input.replace('```', '');
    }

    // replace all \n. They are not needed and can cause issues with JSON parsing
    input = input.replace(/\n/g, '');

    return input;
};

/**
 * This function will remove any base64 data from a string.
 * Useful for sanitizing scraping input before embedding.
 * @param text The text to remove base64 data from.
 * @param minLength The minimum length of base64 data to remove.
 * @returns String with base64 data removed.
 */
export function removeBase64Data(text: string, minLength: number = 50): string {
    // Regular expression to match base64 data
    // Adjust minLength based on the expected size of base64 data
    const base64DataRegex = new RegExp(`[A-Za-z0-9+/]{${minLength},}==`, 'g');

    // Replace all base64 data with an empty string
    return text.replace(base64DataRegex, '');
}

/**
 * This function will average a list of vectors together to produce a single vector representing the average.
 * @param vectors The list of vectors to average.
 * @returns A vector representing the average of the input vectors.
 */
export function averageVectors(vectors: number[][]): number[] {
    const numVectors = vectors.length;
    if (numVectors === 0 || !vectors[0]) return [];

    const length = vectors[0].length;
    const sum = new Array(length).fill(0);

    for (const vector of vectors) {
        vector.forEach((value, i) => {
            sum[i] += value;
        });
    }

    return sum.map((val) => val / numVectors);
}

/**
 * This helper function will take in an array and batch it into smaller arrays.
 * @param array The array to batch.
 * @param batchSize The size of each batch.
 * @returns An array of batches.
 */
export const batch = <T>(array: T[], batchSize: number): T[][] => {
    const batches: T[][] = [];
    for (let i = 0; i < array.length; i += batchSize) {
        batches.push(array.slice(i, i + batchSize));
    }
    return batches;
};

/**
 * Split an array into a Map keyed by a given key function. (a polyfill for Object.groupBy)
 * @param array The array to split.
 * @param keyGetter The function to get a key from the array.
 * @returns A Map of arrays keyed by the keyGetter function.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupBy = <T, K extends keyof any>(
    array: T[],
    keyGetter: (item: T) => K
): Record<K, T[]> => {
    const map = new Map<K, T[]>();
    array.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return Array.from(map).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value }),
        {} as Record<K, T[]>
    );
};

/**
 * This function will shuffle an array in place.
 * @param array The array to shuffle.
 * @returns A shuffled array.
 */
export const shuffleArray = <T>(array: T[]): T[] => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        // @ts-expect-error - This is a valid swap
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

/**
 * This function will hash a message using the SHA-256 algorithm.
 * @param message The message to hash.
 * @returns A promise that resolves to the hashed message.
 */
export async function sha256Hash(message: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
}

/**
 * A function to remove a given search param from the URL
 * @param param The search param to remove
 * @param pathname The pathname to remove the search param from
 * @returns the new URL without the search param
 */
export function removeFromURL(param: string, pathname: string): string {
    const url = new URL(pathname, window.location.origin);
    url.searchParams.delete(param);
    return url.toString();
}

/**
 * This function will truncate a string to a given length and append an ellipsis.
 * @param text The text to truncate.
 * @param maxLength The maximum length of the truncated text.
 * @param ellipsis The ellipsis to append to the truncated text.
 * @returns A truncated (if required) string.
 */
export function truncateText(text: string, maxLength: number, ellipsis = '...'): string {
    if (text.length <= maxLength) {
        return text;
    }
    return text.slice(0, maxLength - ellipsis.length) + ellipsis;
}

export function getCookieValue(cookies: Cookie[], name: string, domain?: string): string | null {
    const cookie = domain
        ? cookies.find((cookie) => cookie.name === name && cookie.domain === domain)
        : cookies.find((cookie) => cookie.name === name);

    return cookie ? cookie.value : null;
}

/**
 * This function will convert a plain JS object to a FormData object.
 * @param obj The object to convert.
 * @returns The FormData object.
 */
export function convertObjectToFormData(obj: Record<string, string | Blob>) {
    const formData = new FormData();
    Object.keys(obj).forEach((key) => formData.append(key, obj[key] as string | Blob));
    return formData;
}

/**
 * This helper function will combine multiple arrays into a single array in a zipped fashion.
 * Meaning that the first element of each array will be combined into a single array, then the second element of each array, and so on.
 * @param arrays The arrays to zip together.
 * @returns A single array containing the zipped elements.
 */
export function zipArrays<T>(arrays: T[][]): T[] {
    if (arrays.length === 0) return [];

    const maxLength = Math.max(...arrays.map((arr) => arr.length));
    const result: T[] = [];

    for (let i = 0; i < maxLength; i++) {
        for (const arr of arrays) {
            if (i < arr.length && arr[i] !== undefined) {
                result.push(arr[i] as T);
            }
        }
    }

    return result;
}
